import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { DataGrid } from '@mui/x-data-grid';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'rsuite/dist/rsuite.min.css';
import { SelectPicker } from 'rsuite';


const EvaluationStudent = ({ columns, rows }) => {

    const [selected, setSelected] = useState(null);


    return (
        <Container className="mt-5 mb-5">
            <Row>
                <Col xs={12}>
                    <Row className="mb-2">
                        <Col xs={12} md={6}>
                            <h3>Öğrenci Değerlendirme</h3>
                        </Col>
                        <Col xs={12} md={6} className="text-md-end">
                            <h6>{selected}</h6>
                        </Col>
                    </Row>
                    <div style={{ width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            onRowClick={(row) => {
                                setSelected(row.row.student);
                            }
                            }
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default EvaluationStudent;
