import React, { Component } from "react";
import { View, Text, Dimensions, Image, TouchableOpacity, Alert } from "react-native";
import Context from "../context";
import { connect } from "react-redux";
import { getClasses, updateClass, getClass } from "../redux/actions/class";
import { getLessons, removeLesson } from "../redux/actions/lesson";
import { getUsers } from "../redux/actions/user";
import { getStudents } from "../redux/actions/student";
import { Table, Button, Form, Row, Col, Modal, Nav } from "react-bootstrap";
import { getTeachers } from '../redux/actions/teachers'
import { Link } from "react-router-dom";
import "./style.css";
import evaluations from "../redux/reducers/evaluations";
import { getEvaluations } from "../redux/actions/evaluation";
import LessonComponent from "./lessonComponent";

const { height } = Dimensions.get("screen");

export class DetailClass extends Component {
  state = {
    name: "",
    students: [],
    lessons: [],
    newLessonModal: false,
    newLessonItem: {
      lesson: {
        lesson: null,
        evaluations: []
      },
      teacher: null,
      lessonHour: 1,
    },
    removeLessonModal: false,
    selectedLessonItem: null,
  };

  componentDidMount() {
    const url = window.location.href;
    const id = url.substring(url.lastIndexOf('/') + 1)

    this.props.getClass(id, (e) => {
      console.log('class', e)
      this.setState(e)
    })

    this.props.getLessons();
    this.props.getUsers();
    this.props.getStudents();
    this.props.getTeachers()
    this.props.getEvaluations()
  }

  remove = () => {
    const { selectedItem } = this.state;
    this.props.removeStudent(selectedItem.id, (e) => {
      this.setState({ selectedItem: null, removeModal: false });
    });
  };

  removeLesson = () => {
    const { selectedLessonItem } = this.state;
    this.props.removeLesson(selectedLessonItem.id, (e) => {

      let copyItems = [...this.state.lessons];
      copyItems = copyItems.filter((item) => item.id !== selectedLessonItem.id)
      this.setState({ lessons: copyItems, selectedLessonItem: null, removeLessonModal: false });

    });
  }

  update = () => {

    const data = { ...this.state }
    console.log('data', data)

    this.props.updateClass(data.id, data, (e) => {
      this.setState(e)
      console.log('e', e)
      alert('Güncellendi!')
    })

  }

  render() {
    const { name, students, lessons } = this.state;
    const { users, teachers } = this.props;

    console.log("lessons", lessons);

    return (
      <Context
        title="Sınıflar"
        context={
          <View style={{ minHeight: height }}>
            <div className="container mt-5">
              <Form>
                <Row>
                  <Col xs={12} md={12} className="mb-2">
                    <Form.Control
                      type="text"
                      placeholder="Sınıf Adı"
                      onChange={(e) => {
                        this.setState({ name: e.target.value });
                      }}
                      value={name}
                    />
                  </Col>
                </Row>
              </Form>

              <View
                style={{
                  flexDirection: "row",
                  marginTop: 20,
                  alignItems: "center",
                }}
              >
                <h5>Dersler</h5>
                <TouchableOpacity
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 25,
                    backgroundColor: "#2ecc71",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: 10,
                  }}
                  onPress={() => {
                    // this.setState({
                    //   lessons: [
                    //     ...this.state.lessons,
                    //     { lesson: null, teacher: null, lessonHour: 1, edit: 'add' },
                    //   ],
                    // });
                    this.setState({ newLessonModal: true })
                  }}
                >
                  <h3 style={{ color: "white" }}>+</h3>
                </TouchableOpacity>
              </View>

              <Table striped bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th>Sıra</th>
                    <th >Ders Adı</th>
                    <th >Öğretmen</th>
                    <th style={{ width: 120 }}>Ders Saati</th>
                    <th>Sil</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    lessons.map((item, index) => {
                      console.log('item', this.props.evaluations)
                      return (
                        <LessonComponent
                          id={item.id}
                          evaluations={this.props.evaluations}
                          selectedItems={item.lesson?.evaluations}
                          index={index}
                          lessonName={item.lesson?.lesson?.name}
                          teacherName={item.teacher?.account?.first_name + " " + item.teacher?.account?.last_name}
                          lessonHour={item.lessonHour}
                          addOrRemoveEvaluation={(evaluation) => {

                            let copyItems = [...lessons];
                            let copyItem = copyItems[index];

                            let selectedEvaluation = copyItem.lesson.evaluations.find((eva) => eva.evaluation.id === evaluation.id)
                            if (selectedEvaluation) {
                              copyItem.lesson.evaluations = copyItem.lesson.evaluations.filter((eva) => eva.evaluation.id !== evaluation.id)
                            } else {
                              const newItem = {
                                evaluation: evaluation,
                                degree: 0
                              }
                              copyItem.lesson.evaluations.push(newItem)
                            }
                            copyItems.splice(index, 1, copyItem)
                            this.setState({ lessons: copyItems })
                          }}

                          onChangeDegree={(evaluation, degree) => {
                            let copyItems = [...lessons];
                            let copyItem = copyItems[index];
                            let selectedEvaluation = copyItem.lesson.evaluations.find((eva) => eva.evaluation.id === evaluation.id)
                            selectedEvaluation.degree = degree
                            copyItems.splice(index, 1, copyItem)
                            this.setState({ lessons: copyItems })
                          }}

                          removeLesson={(id) => {

                            let copyItem = { ...item }
                            if (!copyItem.remove)
                              copyItem.remove = true
                            else
                              delete copyItem.remove

                            let copyItems = [...lessons];
                            copyItems.splice(index, 1, copyItem)
                            this.setState({ lessons: copyItems })

                          }}

                          remove={item.remove}

                        />
                      )
                    })
                  }
                </tbody>
              </Table>

              <View
                style={{
                  flexDirection: "row",
                  marginTop: 20,
                  alignItems: "center",
                }}
              >
                <h5>Öğrenciler</h5>
                <TouchableOpacity
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 25,
                    backgroundColor: "#2ecc71",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: 10,
                  }}
                  onPress={() => {
                    this.setState({
                      students: [...this.state.students, { edit: 'add' }],
                    });
                  }}
                >
                  <h3 style={{ color: "white" }}>+</h3>
                </TouchableOpacity>
              </View>

              <Table striped bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th>Sıra</th>
                    <th className="name-column">Öğrenci</th>
                    <th className="name-column">Detay</th>
                    <th>Sil</th>
                  </tr>
                </thead>
                <tbody>
                  {students.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="name-column">
                        {
                          <select disabled={item.edit && item.edit === 'remove' ? true : false}
                            className="form-select"
                            value={item.id}
                            onChange={(e) => {
                              let copyItems = [...students];
                              let copyItem = copyItems[index]
                              let propsStudent = this.props.students.find(
                                (stu) =>
                                  stu.id === Number.parseInt(e.target.value)
                              );
                              let selectedStudent = { ...copyItem, ...propsStudent }
                              if (!item.edit)
                                selectedStudent['edit'] = 'change'

                              copyItems.splice(index, 1, selectedStudent);
                              this.setState({ students: copyItems }, () =>
                                console.log("state", this.state)
                              );
                            }}
                          >
                            <option value="">Öğrenci Seç</option>
                            {this.props.students.map((student, i) => {
                              return (
                                <option value={student.id} key={student.id}>
                                  {student.first_name + " " + student.last_name}
                                </option>
                              );
                            })}
                          </select>
                        }
                      </td>
                      <td>
                        {
                          item.id ?
                            <Link to={'/student/' + item.id}>
                              <Button variant="info" >Detay</Button>
                            </Link> : null
                        }
                      </td>
                      <td>
                        <Button
                          variant="danger"
                          onClick={() => {
                            let copyItems = [...students];
                            let removeItem = copyItems[index]
                            if (!item.edit || item.edit !== 'remove') {
                              removeItem['edit'] = 'remove'
                            } else if (item.edit && item.edit === 'remove') {
                              delete removeItem.edit
                            }
                            copyItems.splice(index, 1, removeItem);
                            this.setState({ students: copyItems }, () => console.log('stae', this.state));
                          }}
                        >
                          {item.edit && item.edit === 'remove' ? 'Geri Al' : 'Sil'}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Col xs={12} md={12} className="mb-2">

              </Col>
            </div>

            <Button style={{position:'fixed',right:20,bottom:50,height:50,alignItems:'center',justifyItems:'center',width:150,borderRadius:50}} onClick={() => this.update()}>
              Kaydet
            </Button>


            <Modal size="lg" show={this.state.newLessonModal} onHide={() => this.setState({ newLessonModal: false })}>
              <Modal.Header closeButton>
                <Modal.Title>Yeni Ders Ekle</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Row className="">
                    <Col xs={5} md={5} className="mb-2">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Ders Adı</Form.Label>
                        <select
                          className="form-select"
                          onChange={(e) => {
                            let copyItem = { ...this.state.newLessonItem }
                            copyItem.lesson.lesson = this.props.lessons.find((les) => les.id === Number.parseInt(e.target.value))
                            this.setState({ newLessonItem: copyItem })
                          }}>
                          <option value="">Ders Seç</option>
                          {this.props.lessons.map((lesson, i) => {
                            console.log('lessonnnnn', lesson)
                            return (
                              <option value={lesson.id} key={lesson.id}>
                                {lesson.name}
                              </option>
                            );
                          })}
                        </select>
                      </Form.Group>
                    </Col>
                    <Col xs={5} md={5} className="mb-2">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Öğretmen</Form.Label>
                        <select
                          className="form-select"
                          onChange={(e) => {
                            let copyItem = { ...this.state.newLessonItem }
                            copyItem.teacher = this.props.teachers.find((tech) => tech.id === Number.parseInt(e.target.value))
                            this.setState({ newLessonItem: copyItem })
                          }}>
                          <option value="">Öğretmen Seç</option>
                          {this.props.teachers.map((teacher, i) => {
                            return (
                              <option value={teacher.id} key={teacher.id}>
                                {teacher.account?.first_name + " " + teacher.account?.last_name}
                              </option>
                            );
                          })}
                        </select>
                      </Form.Group>
                    </Col>

                    <Col xs={2} md={2} className="mb-2">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Saat</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Ders Saati"
                          onChange={(e) => {
                            let copyItem = { ...this.state.newLessonItem }
                            copyItem.lessonHour = Number.parseInt(e.target.value)
                            this.setState({ newLessonItem: copyItem })
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => this.setState({ newLessonModal: false })}>
                  Kapat
                </Button>
                <Button variant="primary" onClick={() => {
                  let copyItems = [...lessons];
                  let copyItem = { ...this.state.newLessonItem }
                  copyItem['lesson']['evaluations'] = copyItem.lesson.lesson.evaluations
                  copyItems.push(copyItem)
                  this.setState({ lessons: copyItems, newLessonModal: false }, () => console.log('new state', this.state))
                }}>
                  Ekle
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal size="lg" show={this.state.removeLessonModal} onHide={() => this.setState({ removeLessonModal: false })}>
              <Modal.Header closeButton>
                <Modal.Title>Dersi Sil</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>Dersi Silmek İstediğinize Emin Misiniz?</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => this.setState({ removeLessonModal: false })}>
                  Kapat
                </Button>
                <Button variant="danger" onClick={() => this.removeLesson()}>
                  Sil
                </Button>
              </Modal.Footer>
            </Modal>

          </View>
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  classes: state.Classes.classes,
  lessons: state.Lessons.lessons,
  users: state.Users.users,
  students: state.Students.students,
  teachers: state.Teachers.teachers,
  evaluations: state.Evaluations.evaluations
});

const mapDispatchToProps = {
  getClasses,
  getLessons,
  getUsers,
  getStudents,
  updateClass,
  getClass,
  getTeachers,
  getEvaluations,
  removeLesson
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailClass);
