import React, { Component } from 'react'
import { View, Text, Dimensions } from 'react-native'
import Context from '../context'
import { connect } from 'react-redux'
import { getEvaluations } from '../redux/actions/evaluation'
import { getJobs, createJob, updateJob, removeJob } from '../redux/actions/job'
import { Table, Button, Form, Row, Col, Modal, Nav } from 'react-bootstrap';
import JobItem from './job'

const { height } = Dimensions.get('screen')

export class Job extends Component {

  state = {
    jobs: [],
    name: '',
    search: '',
    removeModal: false,
    editModal: false,
    selectedItem: null,
    selectedEvaItem: null,
    degreeValue: 1
  }

  componentDidMount() {
    this.props.getEvaluations()
    this.props.getJobs((e) => {
        console.log('e',e)
      this.setState({ jobs: e })
    })
  }

  componentDidUpdate(prevProps){
    if(prevProps.jobs!==this.props.jobs){
      if(this.props.jobs!==this.state.jobs)
        this.setState({jobs:this.props.jobs})
    }
  }

  create = (name) => {
    this.props.createJob({
      name
    }, (e) => {
      console.log('oluşturma', e)
      this.setState({jobs:[...this.state.jobs,e],name:''})
    })
  }

  update = (id, name) => {
    const {selectedItem}=this.state
    console.log('selectedItems',selectedItem.evaluations)
    this.props.updateJob(selectedItem.id, {
      name:selectedItem.name,
      evaluations:selectedItem.evaluations
    }, (e) => {
      this.setState({selectedItem:null,editModal:false,})
    })

  }

  addEvaluation = (id, evaID, degree) => {
    console.log('ekleme')
    this.props.updateJob(id, {
      type: 'addEvaluation',
      id: evaID,
      degree
    }, (e) => {
      console.log('güncelleme', e)
    })
  }

  remove = () => {
    const {selectedItem} = this.state
    this.props.removeJob(selectedItem.id, (e) => {
        this.setState({selectedItem:null,removeModal: false})
    })
  }

  render() {

    const { evaluations } = this.props
    const { jobs,name, search, removeModal, editModal, selectedItem, selectedEvaItem, degreeValue } = this.state

    const filteredData = jobs.filter(item =>
      item.name.toLowerCase().includes(search.toLowerCase())
    );

    return (
      <Context title="Meslekler" context={
        <View style={{ minHeight: height }}>
          <div className="container mt-5">
            <Form>
              <Row>
                <Col xs={12} md={8} className="mb-2">
                  <Form.Control
                    type="text"
                    placeholder="Meslek Adı"
                    value={name}
                    onChange={(e) => {
                      this.setState({ name: e.target.value })
                    }}
                  />
                </Col>
                <Col xs={12} md={4} className="mb-2">
                  <Button className="w-100" onClick={()=>this.create(name)}>Oluştur</Button>
                </Col>
              </Row>
            </Form>
            <Row className="mb-3" style={{ marginTop: 20 }}>
              <Col xs={12} className="text-right">
                <Form.Control
                  type="text"
                  placeholder="Arama..."
                  onChange={(e) => {
                    this.setState({ search: e.target.value })
                  }}
                  value={search}
                  className="search-input"
                />
              </Col>
            </Row>

            <Table striped bordered hover className="mt-3">
              <thead>
                <tr>
                  <th>Sıra</th>
                  <th className="name-column">Meslek Adı</th>
                  <th>Değer Sayısı</th>
                  <th>Düzenle</th>
                  <th>Sil</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="name-column">{item.name}</td>
                    <td>{item.evaluations.length}</td>
                    <td>
                      <Button variant="info" onClick={() => {
                        this.setState({ selectedItem: { ...item }, editModal: true })
                      }}>Düzenle</Button>
                    </td>
                    <td>
                      <Button variant="danger" onClick={() => {
                        this.setState({ selectedItem: { ...item }, removeModal: true })
                      }}>Sil</Button>
                    </td>
                  </tr>

                ))}
              </tbody>
            </Table>

          </div>
          <Modal show={removeModal} onHide={() => this.setState({ removeModal: false, selectedItem: null })}>
            <Modal.Header closeButton>
              <Modal.Title>Silmek İstediğinizden Emin Misiniz?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.setState({ removeModal: false, selectedItem: null })}>
                Hayır
              </Button>
              <Button variant="danger" onClick={()=>this.remove()}>
                Evet
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={editModal} onHide={() => this.setState({ editModal: false, selectedItem: null, })}>
            <Modal.Header closeButton>
              <Modal.Title>Mesleği Düzenle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Control
                type="text"
                value={selectedItem?.name}
                onChange={(e) => {
                  this.setState({ selectedItem: { ...selectedItem, name: e.target.value } }, () => console.log('state', this.state))
                }}
              />

              {
                selectedItem ?
                  <select className="form-select" value={selectedEvaItem?.id} onChange={(e) => {
                    this.setState({ selectedEvaItem: evaluations.find((eva) => eva.id === Number.parseInt(e.target.value)) })
                  }} style={{ marginTop: 20, marginBottom: 20 }}>
                    <option value="">Değerlendirme Ekle</option>
                    {
                      evaluations.filter((eva) => selectedItem.evaluations.findIndex((item) => item.evaluation.id === eva.id) === -1).map((item) => {
                        return (
                          <option value={item.id} key={item.id}>{item.name}</option>
                        )
                      })
                    }
                  </select> : null
              }

              {
                selectedEvaItem ?
                  <div className="d-flex align-items-center" style={{ marginBottom: 10 }}>
                    <label style={{ marginRight: 20 }} htmlFor="numberInput" className="font-weight-bold mr-2">Katsayı</label>
                    <Form.Control
                      type="number"
                      placeholder="Min Değer"
                      onChange={(e) => this.setState({ degreeValue: Number.parseInt(e.target.value) })}
                      className="form-control form-control-sm"
                      style={{ width: 120 }}
                      value={degreeValue}
                      max={10}
                      min={1}
                    />
                  </div> : null
              }

              {
                selectedEvaItem ?
                  <Button style={{ marginBottom: 20 }} className="w-100" onClick={() => {
                    this.setState({
                      selectedItem: {
                        ...selectedItem,
                        evaluations: [...selectedItem.evaluations, {
                          evaluation: selectedEvaItem,
                          degree: degreeValue,
                          edit: 'add'
                        }]
                      },
                      degreeValue: 1,
                      selectedEvaItem: null
                    }, () => console.log('state', this.state))
                  }}>Ekle</Button>
                  : null
              }
              <h6>Eklenen Değerlendirmeler</h6>

              <Table striped bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th className="name-column">Değerlendirme Adı</th>
                    <th>Min Değer</th>
                    <th>Sil</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    selectedItem?.evaluations?.filter((filt) => filt.edit !== "remove").map((item,index) => {
                      return (
                        <tr key={index}>
                          <td className="name-column">{item?.evaluation?.name}</td>
                          <td>
                            <Form.Control
                              type="number"
                              placeholder="Min Değer"
                              onChange={(e) => {
                                let copyList = [...selectedItem?.evaluations?.filter((filt) => filt.edit !== "remove")]
                                let copyItem={...item}
                                copyItem['min_degree']=Number.parseInt(e.target.value)
                                
                                if(copyItem.edit){
                                  if(!copyItem.edit==='add'){
                                    copyItem['edit']='change'
                                  }
                                }else{
                                  copyItem['edit']='change'
                                }

                                copyList.splice(index,1,copyItem)
                                this.setState({
                                  selectedItem:{
                                    ...selectedItem,
                                    evaluations:copyList
                                  }
                                },()=>console.log('state',this.state))
                                
                              }}
                              className="form-control form-control-sm"
                              style={{ width: 50 }}
                              value={item.min_degree}
                              max={10}
                              min={1}
                            />
                          </td>

                          <td>
                            <Button variant="danger" onClick={() => {
                              
                              let copyList = [...selectedItem?.evaluations]
                              let copyItem={...item}
                              const copyIndex = copyList.findIndex((copyEva)=>copyEva.evaluation.id===copyItem.evaluation.id)
                              copyItem['edit']='remove'
                              copyList.splice(copyIndex,1,copyItem)
                              this.setState({
                                selectedItem:{
                                  ...selectedItem,
                                  evaluations:copyList
                                }
                              },()=>console.log('state',this.state))
                            }}>Sil</Button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.setState({ editModal: false, selectedItem: null })}>İptal</Button>
              <Button variant="primary" onClick={()=>this.update()}>Kaydet</Button>
            </Modal.Footer>
          </Modal>


        </View>
      } />
    )
  }
}

const mapStateToProps = (state) => ({
  evaluations: state.Evaluations.evaluations,
  jobs: state.Jobs.jobs
})

const mapDispatchToProps = {
  getEvaluations,
  getJobs,
  createJob,
  updateJob,
  removeJob
}

export default connect(mapStateToProps, mapDispatchToProps)(Job)