import React, { Component } from 'react'
import { Table, Button, Form, Row, Col, Modal, Nav, } from "react-bootstrap";



export default class StudentComponent extends Component {

    state = {
        isOpen: false,
        month: new Date().getMonth(),
        newScores: []
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.scores !== this.props.scores) {
            this.setState({ newScores: [...this.props.scores] })
        }
    }

    componentDidMount() {
        this.setState({ newScores: this.props.scores ? [...this.props.scores] : [] })
    }


    ozelYuvarla = (sayi) => {
        let altTamsayi = Math.floor(sayi); // Sayının alt tam kısmını al

        if (sayi - altTamsayi >= 0.5) {
            // Sayının ondalık kısmı 0.5'ten büyük veya eşitse, yukarı yuvarla
            return Math.ceil(sayi);
        } else {
            // Sayının ondalık kısmı 0.5'ten küçükse, aşağı yuvarla
            return altTamsayi;
        }
    }

    save = () => {
        const { newScores } = this.state;

        this.props.save(newScores);
    }

    render() {

        const { newScores, month } = this.state;

        console.log('newScores', newScores);
        console.log('month', month);

        return (

            <Row style={{ padding: '10px 0', borderBottom: '1px solid rgba(0,0,0,0.1)', marginBottom: '10px', }}>
                <Row>
                    <Col md={11}>
                        <h5>{this.props.studentName}</h5>
                    </Col>
                    <Col md={1} style={{ justifyItems: 'flex-end', alignItems: 'flex-end' }}>
                        <Button style={{ color: 'blue' }} variant="second" onClick={() => { this.setState({ isOpen: !this.state.isOpen }) }}>Ayrıntılar</Button>
                    </Col>
                </Row>

                {
                    this.state.isOpen && (
                        <Row style={{ backgroundColor: 'rgba(0,0,0,0.1)', padding: '10px', margin: '0 20px', borderRadius: '5px' }}>
                            <Col md={12}>

                                <Row style={{ marginBottom: '2em' }}>
                                    <Form.Group style={{ width: '150px', marginBottom: '1em' }}>
                                        <Form.Label>Ay Seç</Form.Label>
                                        <select className="form-control" value={this.state.month} onChange={(e) => this.setState({ month: Number.parseInt(e.target.value) })}>
                                            <option value={0}> Ocak </option>
                                            <option value={1}> Şubat </option>
                                            <option value={2}> Mart </option>
                                            <option value={3}> Nisan </option>
                                            <option value={4}> Mayıs </option>
                                            <option value={5}> Haziran </option>
                                            <option value={6}> Temmuz </option>
                                            <option value={7}> Ağustos </option>
                                            <option value={8}> Eylül </option>
                                            <option value={9}> Ekim </option>
                                            <option value={10}> Kasım </option>
                                            <option value={11}> Aralık </option>
                                        </select>
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Değerlendirmeler</Form.Label>

                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Değerlendirme</th>
                                                    <th>Not</th>
                                                    <th>Ortalama</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.props.evaluations?.map((evaluation, index) => {

                                                        const selectedScore = newScores.filter((monthItem) => monthItem.month === this.state.month).find((item) => item.evaluation.evaluation.id === evaluation.evaluation.id)?.score;
                                                        let totalScore = 0

                                                        console.log('selectedScore', selectedScore);

                                                        newScores.filter((score) => score.evaluation.evaluation.id === evaluation.evaluation.id).map((score) => {
                                                            totalScore += score.score
                                                        })

                                                        const scoreLength = newScores.filter((score) => score.evaluation.evaluation.id === evaluation.evaluation.id).length

                                                        return (
                                                            <tr key={index}>
                                                                <td>{evaluation.evaluation.name}</td>
                                                                <td>
                                                                    <select className="form-control" value={selectedScore ? selectedScore : 0} onChange={(event) => {
                                                                        let score = newScores.find((item) => item.evaluation.evaluation.id === evaluation.evaluation.id && item.month === this.state.month);
                                                                        if (score) {
                                                                            score.score = parseInt(event.target.value);
                                                                        } else {
                                                                            newScores.push({ evaluation: evaluation, score: parseInt(event.target.value), month: this.state.month })
                                                                        }
                                                                        this.setState({ newScores: [...newScores] })
                                                                    }}>
                                                                        <option value={0}>Seçiniz</option>

                                                                        {
                                                                            evaluation.evaluation.scales.map((scale, index) => {
                                                                                return (
                                                                                    <option title={scale.desc} key={index} value={scale.score}>{scale.score + ' - ' + scale.name}</option>
                                                                                )
                                                                            })
                                                                        }

                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <p>
                                                                        {
                                                                            evaluation.evaluation.scales.find((scale) => scale.score === this.ozelYuvarla((scoreLength > 0 ? totalScore / scoreLength : totalScore)))?.name
                                                                        }
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>

                                    </Form.Group>

                                </Row>

                                <Row style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                    <Button className="w-100" onClick={() => { this.save()}}>
                                        Kaydet
                                    </Button>
                                </Row>

                            </Col>

                        </Row>
                    )
                }

            </Row>

        )
    }
}
