import axios from 'axios'
import { CLASSES,CREATE_CLASS,UPDATE_CLASS,REMOVE_CLASS } from './types'
import { MAIN_URL, tokenConfig,tokenConfigMultiPart } from './host'



export const getReport = (page,e) => (dispatch, getState) => {

    let config=tokenConfig(getState)

    axios.get(MAIN_URL + '/scores/report/?page='+page, config)
        .then(res => {
            console.log('report',res.data)
            e(res.data)
        })
}

export const removeReportItem = (id,e) => (dispatch, getState) => {

    let config=tokenConfig(getState)

    axios.delete(MAIN_URL + '/scores/'+id+'/', config)
        .then(res => {
            console.log('report',res.data)
            e(res.data)
        })
}

export const createScore = (data,e) => (dispatch, getState) => {

    let config=tokenConfig(getState)

    axios.post(MAIN_URL + '/scores/',data, config)
        .then(res => {

            e(res.data)
        })
}


export const getScores = (_class,lesson,month,e) => (dispatch, getState) => {

    let config=tokenConfig(getState)

    axios.get(MAIN_URL + '/scores/?_class='+_class+'&lesson='+lesson+'&month='+month, config)
        .then(res => {

            e(res.data)
        })
}

export const getScoresStudent = (student,e) => (dispatch, getState) => {

    let config=tokenConfig(getState)

    axios.get(MAIN_URL + '/scores/?student='+student, config)
        .then(res => {

            e(res.data)
        })
}


export const getScoreControl = (e) => (dispatch, getState) => {

    let config=tokenConfig(getState)
    console.log('score',config)

    axios.get(MAIN_URL + '/scores/?control=true', config)
        .then(res => {

            e(res.data)
        })
}
