import React, { Component } from "react";
import { View, Text, Dimensions, Image, TouchableOpacity } from "react-native";
import Context from "../context";
import { connect } from "react-redux";
import { getClasses, getClass } from "../redux/actions/class";
import { Table, Button, Form, Row, Col, Modal, Nav } from "react-bootstrap";
import "./style.css";
import { createScore, getScores, getReport, removeReportItem } from '../redux/actions/score'
import { icons } from '../icons'
import StudentComponent from "./studentComponent";
import ScoreReport from "./report";
import Monthly from "./monthly";
import EvaluationStudent from "./studentEvulation";
import { SelectPicker } from 'rsuite';
import LoadingModal from "../Components/loading";


const { height } = Dimensions.get("screen");

const monthNames = ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık']



export class Score extends Component {
  state = {
    _class: null,
    lesson: null,
    month: new Date().getMonth(),
    scores: [],
    scaleModal: false,
    scaleModalContent: [],
    report: {
      data: [],
      page: 1,
      removeModal: false,
      removeId: null
    },
    loading: false

  };

  componentDidMount() {
    this.props.getClasses();
    this.getReport(1)
  }


  create = () => {
    const { scores, _class } = this.state
    this.props.createScore({
      class: _class.id,
      scores
    }, (e) => {

      if (e)
        alert('Kaydedildi!')

    })
  }

  createV2 = (scores) => {
    const { _class } = this.state
    this.setState({ loading: true })
    this.props.createScore({
      class: _class.id,
      scores
    }, (e) => {
      setTimeout(() => {
        this.setState({ loading: false })
        if (e)
          alert('Kaydedildi!')
      }, 100)


    })
  }

  getItems = () => {

    const { _class, lesson, month } = this.state
    this.setState({ loading: true })
    if (_class && lesson) {
      this.props.getScores(_class.id, lesson.id, month, (e) => {
        
        setTimeout(() => {
          this.setState({ scores: e, loading: false })
        }, 100)
      })
    }

  }

  geriyeDogruAylariListele = () => {
    const bugun = new Date(); // Bugünün tarihini al

    let ayListesi = []; // Ay listesi için boş bir dizi oluştur

    // Bugünün ay ve yılını al
    let ay = bugun.getMonth() + 1; // JavaScript'te aylar 0'dan başlar, bu yüzden +1 ekliyoruz
    let yil = bugun.getFullYear();

    // Eylül ayına (9. ay) kadar geriye doğru giderek ayları listele
    while (ay !== 9 || yil !== (bugun.getFullYear() - 1)) { // Eylül ayına ve bir önceki yıla kadar geriye git
      ayListesi.push({ ay: ay, yil: yil }); // Ay ve yılı ayListesi'ne ekle

      // Ayı azalt, eğer ocak ayına gelirsek yılı da azalt
      if (ay === 1) {
        ay = 12; // Aralık ayı
        yil -= 1; // Bir önceki yıl
      } else {
        ay -= 1; // Önceki ay
      }
    }

    // Son olarak, Eylül ayını da listeye ekle
    ayListesi.push({ ay: 9, yil: yil });

    return ayListesi;
  }

  ozelYuvarla = (sayi) => {
    let altTamsayi = Math.floor(sayi); // Sayının alt tam kısmını al

    if (sayi - altTamsayi >= 0.5) {
      // Sayının ondalık kısmı 0.5'ten büyük veya eşitse, yukarı yuvarla
      return Math.ceil(sayi);
    } else {
      // Sayının ondalık kısmı 0.5'ten küçükse, aşağı yuvarla
      return altTamsayi;
    }
  }


  gridColRender = () => {
    const { lesson } = this.state
    

    let exampleColumns = [
      { field: 'student', headerName: 'Öğrenci', width: 200, },

    ];

    lesson?.lesson?.evaluations.map((evaluationItem, index) => {

      const valueOptions = evaluationItem.evaluation.scales.map((scale) => ({ label: scale.score + ' - ' + scale.name, value: scale.score }))

      exampleColumns.push({
        field: 'evaluation' + evaluationItem.id,
        headerName: evaluationItem.evaluation.name,
        width: 200,
        valueOptions,
        renderCell: (params) => {
          
          return (
            <SelectPicker
              data={valueOptions?.map(option => ({ label: option.label, value: option.value })) || []}
              value={params.value}
              onChange={(value) => {

                const { scores } = this.state

                const isItemIndex = scores.findIndex((itemScore) => (itemScore.student.id?itemScore.student.id === params.row.id:itemScore.student === params.row.id) && itemScore.evaluation.id === evaluationItem.id && itemScore.month === this.state.month)
                let copyScores = [...scores]

                if (isItemIndex > -1) {
                  let isItem = copyScores[isItemIndex]
                  isItem.score = value
                  copyScores.splice(isItemIndex, 1, isItem)
                } else {
                  const newItem = {
                    student: { id: params.row.id },
                    month: this.state.month,
                    score: value,
                    lesson,
                    class: this.state._class,
                    evaluation: { id: evaluationItem.id }
                  }
                  copyScores.push(newItem)
                }

                this.setState({ scores: copyScores })

              }}
              style={{ width: '100%' }}
            />
          )
        }
      })

      exampleColumns.push({
        field: 'average' + evaluationItem.id,
        headerName: 'Ort',
        width: 60,
      })

    })


    return exampleColumns

  }

  gridRowRender = () => {

    const { _class, lesson, scores, month } = this.state

    const exampleRows = [

    ];

    _class?.students.map((student) => {

      let newItem = {}

      newItem = {
        id: student.id,
        student: student.first_name + ' ' + student.last_name,
      }

      lesson?.lesson?.evaluations.map((evaluationItem, index) => {


        const isScore = scores.find((score) => (score.student.id ? score.student.id === student.id : score.student === student.id) && score.evaluation.id === evaluationItem.id && score.month === month)


        newItem['evaluation' + evaluationItem.id] = isScore ? isScore.score : -1


        let totalScore = 0
        const totalScoreList = scores.filter((totalFilter) => (totalFilter.student.id ? totalFilter.student.id === student.id : totalFilter.student === student.id) && totalFilter.evaluation.id === evaluationItem.id)
        totalScoreList.map((score) => {
          totalScore += score.score
        })

        totalScore = Number.parseFloat((totalScore).toFixed(2))

        newItem['average' + evaluationItem.id] = this.ozelYuvarla((totalScore / totalScoreList.length > 0 ? totalScore / totalScoreList.length : totalScore))

      })


      exampleRows.push(newItem)

    })

    return exampleRows

  }


  rowRender = (list, student, evaluation, month, scales = []) => {
    let copyScores = [...list]
    const { lesson, _class } = this.state
    const isItemIndex = copyScores.findIndex((itemScore) => itemScore.student.id === student.id && itemScore.evaluation.id === evaluation.id && itemScore.month === month)
    let copyScalesList = [...scales]

    const currentMonth = new Date().getMonth()

    return (
      <select disabled={currentMonth !== month ? true : false} className="form-select" value={isItemIndex > -1 ? copyScores[isItemIndex].score : -1} onChange={(e) => {

        if (isItemIndex > -1) {
          let isItem = copyScores[isItemIndex]
          isItem.score = Number.parseInt(e.target.value)
          copyScores.splice(isItemIndex, 1, isItem)
        } else {
          const newItem = {
            student,
            month,
            score: Number.parseInt(e.target.value),
            lesson,
            class: _class,
            evaluation: evaluation
          }
          copyScores.push(newItem)
        }

        this.setState({ scores: copyScores })

      }}>
        <option value={-1}>Boş</option>
        {
          copyScalesList.sort((a, b) => b.score - a.score).map((scale) => {
            return (
              <option value={scale.score}>{scale.score + ' - ' + scale.name}</option>
            )
          })
        }
      </select>
    )



    // return (
    //   <Form.Control
    //     type="number"
    //     placeholder="Puan"
    //     onChange={(e) => {


    //       if (isItemIndex > -1) {
    //         let isItem = copyScores[isItemIndex]
    //         isItem.score = Number.parseInt(e.target.value)
    //         copyScores.splice(isItemIndex, 1, isItem)
    //       } else {
    //         const newItem = {
    //           student,
    //           month,
    //           score: Number.parseInt(e.target.value),
    //           lesson,
    //           class: _class,
    //           evaluation: evaluation
    //         }
    //         copyScores.push(newItem)
    //       }

    //       this.setState({ scores: copyScores }, () => console.log('state', this.state.scores))

    //     }}
    //     className="form-control form-control-sm"
    //     style={{ width: 75 }}
    //     max={10}
    //     min={1}
    //     value={isItemIndex>-1?copyScores[isItemIndex].score:0}
    //   />
    // )
  }


  getReport = (newPage) => {

    const { report } = this.state

    if (newPage === report.page) {
      this.props.getReport(report.page, (e) => {

        const newList = [...report.data, ...e.results]
        this.setState({ report: { data: newList, page: report.page + 1 } })

      })
    }

  }

  removeItem = () => {

    const { report } = this.state

    const newList = report.data.filter((item) => item.id !== report.removeId)

    this.props.removeReportItem(report.removeId, (e) => {
    })

    this.setState({ report: { data: newList, page: report.page, removeModal: false, removeId: null } })


  }


  render() {
    const { _class, lesson, scores, scaleModalContent, report } = this.state;
    const { classes, user } = this.props;
    
    return (
      <Context
        title="Sınıflar"
        context={
          <View style={{ minHeight: height }}>
            <div className="container mt-5">
              <Form>
                <Row>
                  <Col xs={12} md={12} className="mb-2">
                    <select
                      className="form-select"
                      value={_class?.id}
                      onChange={(e) => {
                        this.props.getClass(e.target.value, (result) => {
                          this.setState({ loading: true })
                          
                          setTimeout(() => {
                            this.setState({ _class: result, loading: false })

                          }, 100)
                        })
                      }}
                      style={{ marginTop: 20, marginBottom: 20 }}
                    >
                      <option value="">Sınıf Seç</option>
                      {classes.map((item, index) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                  </Col>
                </Row>
                {_class ? (
                  <Row>
                    <Col xs={12} md={12} className="mb-2">
                      <select
                        className="form-select"
                        value={lesson?.id}
                        onChange={(e) => {
                          const copyItem = _class.lessons.find(
                            (item) =>
                              item.id === Number.parseInt(e.target.value)
                          );
                          this.setState({ lesson: copyItem }, () =>
                            this.getItems()
                          );
                        }}
                        style={{ marginTop: 20, marginBottom: 20 }}
                      >
                        <option value="">Ders Seç</option>
                        {_class.lessons.map((item, index) => {
                          return (
                            <option value={item.id}>
                              {item?.lesson?.lesson?.name}
                            </option>
                          );
                        })}
                      </select>
                    </Col>
                  </Row>
                ) : null}
              </Form>

              <View
                style={{
                  marginTop: 20,
                }}
              >

                {/* {lesson
                  ? lesson?.lesson?.evaluations.map((evaluationItem, index) => {
                    return (
                      <Col xs={12} md={12} className="mb-2">
                        <Table striped bordered hover className="mt-3">
                          <tbody>
                            <tr>
                              <td colSpan={12}>
                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                  <p style={{ flex: 1 }}>{evaluationItem.evaluation.name}</p>
                                  <TouchableOpacity style={{ marginRight: 20 }} onPress={() => {
                                    this.setState({ scaleModal: true, scaleModalContent: [...evaluationItem.evaluation.scales] })
                                  }}>
                                    <Image source={icons.ScaleList} style={{ width: 30, height: 30 }} />
                                  </TouchableOpacity>
                                </View>
                              </td>
                            </tr>
                            <tr>
                              <td>Öğrenciler</td>
                              {
                                this.geriyeDogruAylariListele().map((month) => {
                                  return (
                                    <td>{monthNames[month.ay - 1]}</td>
                                  )
                                })
                              }
                              <td>Ortalama</td>
                            </tr>
                            {_class.students.map((student) => {

                              let totalScore = 0
                              console.log('totalFilter', scores.filter((totalFilter) => totalFilter.student.id === student.id && totalFilter.evaluation.id === evaluationItem.id))
                              scores.filter((totalFilter) => totalFilter.student.id === student.id && totalFilter.evaluation.id === evaluationItem.id).map((score) => {
                                console.log('totalFilterItem', score)
                                totalScore += score.score
                              })

                              totalScore = Number.parseFloat((totalScore).toFixed(2))

                              return (
                                <tr>
                                  <td>
                                    {student.first_name +
                                      " " +
                                      student.last_name}
                                  </td>
                                  {
                                    this.geriyeDogruAylariListele().map((month) => {
                                      return <td>{this.rowRender(scores, student, evaluationItem, month.ay - 1, evaluationItem.evaluation.scales)}</td>
                                    })
                                  }

                                  <td>
                                    <p>
                                      {
                                        evaluationItem.evaluation.scales.find((scale) => scale.score === this.ozelYuvarla((totalScore / scores.filter((score) => score.student.id === student.id && score.evaluation.id === evaluationItem.id).length > 0 ? totalScore / scores.filter((score) => score.student.id === student.id && score.evaluation.id === evaluationItem.id).length : totalScore)))?.name

                                      }
                                    </p>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Col>
                    );
                  })
                  : null} */}
                {
                  // _class?.students.map((student) => {
                  //   return (
                  //     <StudentComponent studentName={student.first_name + ' ' + student.last_name} evaluations={lesson?.lesson?.evaluations} scores={scores.filter((score) => score.student?.id === student.id)}
                  //       save={(scoreList = []) => {

                  //         let copyScoreList = []

                  //         scoreList.map((score) => {

                  //           let newScoreItem = {
                  //             student: student,
                  //             month: score.month,
                  //             score: score.score,
                  //             lesson: lesson,
                  //             _class: _class,
                  //             evaluation: score.evaluation
                  //           }

                  //           if (score.id)
                  //             newScoreItem.id = score.id

                  //           copyScoreList.push(newScoreItem)

                  //         })

                  //         this.createV2(copyScoreList)

                  //       }} />
                  //   )
                  // })
                }
              </View>

              {
                _class && lesson ?
                  <Row>
                    <Monthly changeMonth={(month) => {
                      this.setState({ month }, () => {
                        this.getItems()
                      })
                    }} />

                    <EvaluationStudent columns={this.gridColRender()} rows={this.gridRowRender()} />

                    <Col xs={12} md={12} className="mb-2">
                      <Button className="w-100" onClick={() => { this.createV2([...this.state.scores]) }}>
                        Kaydet
                      </Button>
                    </Col>
                  </Row> : null
              }
            </div>

            <Modal show={this.state.report.removeModal} onHide={() => this.setState({ report: { ...this.state.report, removeModal: false } })}>
              <Modal.Header closeButton>
                <Modal.Title>Puanı Sil</Modal.Title>
              </Modal.Header>
              <Modal.Body>Puanı Silmek İstediğinden Emin Misin?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => this.setState({ report: { ...this.state.report, removeModal: false } })}>
                  İptal
                </Button>
                <Button variant="primary" onClick={() => {
                  this.removeItem()
                }}>
                  Sil
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal className="modal-lg" show={this.state.scaleModal} onHide={() => this.setState({ scaleModal: false, scaleModalContent: [], })}>
              <Modal.Header closeButton>
                <Modal.Title>Değerlendirme Skalaları</Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <Table striped bordered hover className="mt-3">
                  <thead>
                    <tr>
                      <th>Skala Adı</th>
                      <th>Açıklaması</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      scaleModalContent.map((scaleItem) => {
                        return (
                          <tr>
                            <td style={{ flex: 1 }}>{scaleItem.name}</td>
                            <td style={{ flex: 2 }}>{scaleItem.desc}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => this.setState({ scaleModal: false, scaleModalContent: null })}>Kapat</Button>
              </Modal.Footer>
            </Modal>

            <LoadingModal show={this.state.loading} message="Yükleniyor..." />

            {
              !_class && user?.user_type === 2 ?
                <ScoreReport data={report.data} onPagination={(page) => {

                  this.getReport(page)

                }} removeItem={(id) => {
                  this.setState({ report: { ...this.state.report, removeModal: true, removeId: id } })
                }} /> : null
            }

          </View>
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  classes: state.Classes.classes,
  user: state.User.user
});

const mapDispatchToProps = {
  getClasses,
  getClass,
  createScore,
  getScores,
  getReport,
  removeReportItem
};

export default connect(mapStateToProps, mapDispatchToProps)(Score);
