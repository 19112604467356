import React, { useState, useEffect } from 'react';
import { DataGrid, GridDeleteIcon } from '@mui/x-data-grid';
import { Container, Row, Col } from 'react-bootstrap';
import 'rsuite/dist/rsuite.min.css';
import { IconButton } from '@mui/material';

const ScoreReport = ({ onChangeDate = () => { }, onPagination = () => { }, data = [], removeItem=()=>{} }) => {
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'teacher', headerName: 'Öğretmen', width: 130 },
        { field: 'student', headerName: 'Öğrenci', width: 130 },
        { field: '_class', headerName: 'Sınıf', width: 70 },
        { field: 'lesson', headerName: 'Ders', width: 130 },
        { field: 'evaluation', headerName: 'Değerlendirme', width: 200 },
        { field: 'score', headerName: 'Deger', width: 200 },
        { field: 'create_date', headerName: 'O. Tarih', width: 200 },
        {
            field: 'actions', headerName: 'Sil', width: 200, type: 'actions',  getActions: (params) => {
                return [
                    <IconButton
                        key="delete"
                        aria-label="delete"
                        onClick={() => {
                            removeItem(params.row.id)
                        }}
                    >
                        <GridDeleteIcon />
                    </IconButton>
                ];
            }
        },
    ];


    useEffect(() => {

        onChangeDate(dateRange);

    }, [dateRange]);

    return (
        <Container className="mt-5">

            <Row className="mt-4">
                <Col>
                    <div style={{ width: '100%' }}>
                        <DataGrid
                            rows={data}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 20, page: 0 }
                                }
                            }}

                            onPaginationModelChange={(params) => {
                                onPagination(params.page + 1)
                            }}

                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default ScoreReport;
