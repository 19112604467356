import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const LoadingModal = ({ show, message }) => {
  return (
    <Modal
      show={show}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body className="text-center">
        <Spinner animation="border" role="status" className="mb-3">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <div>{message || 'Yükleniyor...'}</div>
      </Modal.Body>
    </Modal>
  );
};

export default LoadingModal;
