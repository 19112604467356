import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const Monthly = ({changeMonth=()=>{}}) => {
  const currentMonth = new Date().getMonth();
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const months = [
    'Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran',
    'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'
  ];

  useEffect(() => {
    changeMonth(selectedMonth);
  }, [selectedMonth]);

  return (
    <Container className="mt-5">
      <Row>
        <Col xs={12} className="mb-3">
          <h3>Ay Seçimi</h3>
        </Col>
        {months.map((month, index) => (
          <Col key={index} xs={6} md={3} lg={2} className="mb-3">
            <Button
              variant={selectedMonth === index ? 'primary' : 'outline-primary'}
              style={{
                backgroundColor: selectedMonth === index ? '#007bff' : 'transparent',
                borderColor: '#007bff',
                color: selectedMonth === index ? 'white' : '#007bff',
                width: '100%'
              }}
              onClick={() => setSelectedMonth(index)}
            >
              {month}
            </Button>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Monthly;
